<template>
  <div class="operatelog-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <el-table class="common-table" :data="tableData">
      <el-table-column prop="date" label="操作人"></el-table-column>
      <el-table-column prop="name" label="操作描述"></el-table-column>
      <el-table-column prop="province" label="操作时间"></el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="100"
      layout="total, prev, pager, next,sizes, jumper"
      :total="400"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "系统设置", isLink: false },
        { title: "操作日志", isLink: false },
      ],
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
      ],
    };
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.operatelog-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>